import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'
import Banner from "../../../components/Banner/Edu";
import JoinUs from "../../../components/JoinUs";
import BlogCard from "../../../components/Blog";
import Loader from '../../../components/Loader';

import arrowRight from '../../../assets/images/arrow-right.svg'
import calling from '../../../assets/images/calling.svg'
import chooseIcon from '../../../assets/images/chooseIcon.svg'
import chooseVector from '../../../assets/images/choose-vector.png'
import '../../home.scss'
import { getData } from "../../../api/home";
import FieldCard from "../../../components/Fields";

const Educational = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getData();
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="educational">
      {
        data ?  <>
       <Helmet>
            <title>{data.homeData.title || "Tədris Kursları | Peşəkar Təlimlər"}</title>
            <meta name="description" content={data.homeData.description || "Peşəkar təlimlər haqqında məlumat alın."} />
            <meta name="keywords" content="tədris, kurslar, təlimlər, peşəkar bacarıqlar, təhsil" />
            <meta name="author" content="1ST Group Academy" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="canonical" href="https://1stgroupacademy.com/" />
            
            {/* JSON-LD script əlavə olunur */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "EducationalOrganization",
                "name": "1ST Group Academy",
                "url": "https://1stgroupacademy.com",
                "logo": "https://1stgroupacademy.com/static/media/logo.80077335acef82bf1035e9b1e3531745.svg",
                "description": "Peşəkar təlimlər və tədris kursları ilə bacarıqlarınızı inkişaf etdirin.",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "0992420808",
                  "contactType": "customer service"
                }
              })}
            </script>
          </Helmet>
       <Banner path='home' data={data.homeData}/>
       <div className="fields-home">
         <div className="similar-blogs__title">
           <h2>Tədris sahələri</h2>
           <Link to="/fields">Daha çox <img src={arrowRight} alt="Daha çox kurs kəşf etmək üçün ox işarəsi" /></Link>
         </div>
         <div className="fields__list">
          {
            data.courses.length > 0 ? data.courses.map(course => {
              return(
                <FieldCard key={course.id} data={course}/>
              )
            }) : "Kurs yoxdur..."
           }
        </div>
       </div>
       
       <div className='choose'>
          <div className="choose-wrap">
              <div className="choose-img">
                  <img src={chooseVector} alt="Tədris seçimi vizualı" loading="lazy" />
                  <img src="https://s3-alpha-sig.figma.com/img/c07e/9cb5/abf5ba097a9d6a1edb75eb4c8c84c2d1?Expires=1733702400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ne9Vvrv1N~UJ--dGIFPHgzdQjWQH8BkrrUF81ZT-pZOnDdQdSC-usO~in0rEtJSqx64-xziYEWqH2CxoHKRlKOsFFX8bjv10hgrcVat08qqB7cBrm8gPCcTJttQ5~BSfZHYrk8HNhsdw05HyUSFXvEcex2IPgk8K9n~39IpLgmrNd9dYMrOYyhHFG5F5IbfDZNqwmeFiSVzIoVRfijqgQxH44MrBiTVvx3hz6y3spi4bMIa5fwyPXTfwnPhMlROfeqdgmA-S6zAfQZDekYpLeIAe3yXIUisR2eGcwqcxWBm~UVZLHl~mh3OCbmViMlcI67Di4Y0PzVmcr0FLkt4LZQ__" alt="" loading="lazy"/>
              </div>
              <div className="choose-content">
                  <h1>{data.homeData.innerTitle}</h1>
                  <p>{data.homeData.innerDescription}</p>
                  <ul className="choose-content-items">
                      <li>
                          <img src={chooseIcon} alt="" />
                          <span>{data.homeData.option1}</span>
                      </li>
                      <li>
                          <img src={chooseIcon} alt="" />
                          <span>{data.homeData.option2}</span>
                      </li>
                      <li>
                          <img src={chooseIcon} alt="" />
                          <span>{data.homeData.option3}</span>
                      </li>
                      <li>
                          <img src={chooseIcon} alt="" />
                          <span>{data.homeData.option4}</span>
                      </li>
                  </ul>
              </div>
          </div>
        </div>
       <div className="similar-blogs">
         <div className="similar-blogs__title">
           <h2>Bloqlar</h2>
           <Link to="/blogs">Daha çox <img src={arrowRight} alt="" /></Link>
         </div>
         <div className="similar-blogs__list">
           {
            data.blogs.length > 0 ? data.blogs.map(blog => {
              return(
                <BlogCard key={blog.id} data={blog}/>
              )
            }) : "Bloq yoxdur..."
           }
         </div>
       </div>
       <JoinUs />
       <div className="calling">
         <a href="tel:0992420808">
           <img src={calling} alt="Bizimlə əlaqə saxlamaq üçün zəng edin" />
         </a>
       </div>
        </> : <Loader />
      }
    </div>
  );
};

export default Educational;
