// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-message {
  width: 40%;
  text-align: center;
  margin: 48px auto 0;
}
.alert-message img {
  margin: auto;
}
.alert-message p {
  font-size: 28px;
  font-weight: 300;
  color: #212121;
  margin-top: 42px;
}`, "",{"version":3,"sources":["webpack://./src/components/Alert/alert.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AAAI;EACI,YAAA;AAER;AAAI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AAER","sourcesContent":[".alert-message{\n    width: 40%;\n    text-align: center;\n    margin: 48px auto 0;\n    img{\n        margin: auto;\n    }\n    p{\n        font-size: 28px;\n        font-weight: 300;\n        color: #212121;\n        margin-top: 42px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
