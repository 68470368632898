// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidenav {
  position: fixed;
  z-index: 111;
  width: calc(100% - 24px);
  height: 100%;
  background-color: #fff;
  top: 96px;
  left: 12px;
  border-radius: 8px;
}
.sidenav__header {
  padding: 12px 16px;
  height: 48px;
  border-bottom: 1px solid #f1f5f7;
  display: flex;
  align-items: center;
  gap: 24px;
}
.sidenav__header a {
  font-size: 14px;
  font-weight: 300;
  color: #151515;
}
.sidenav__links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.sidenav__links li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.sidenav__links li a {
  font-size: 14px;
  color: #151515;
  font-weight: 500;
}
.sidenav .active {
  color: #008ADF;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidenav/sidenav.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,wBAAA;EACA,YAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;AACF;AAAE;EACM,kBAAA;EACA,YAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAER;AADQ;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAGR;AAAI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAER;AADQ;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAGZ;AAFY;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AAIhB;AAAI;EACI,cAAA;AAER","sourcesContent":[".sidenav {\n  position: fixed;\n  z-index: 111;\n  width: calc(100% - 24px);\n  height: 100%;\n  background-color: #fff;\n  top: 96px;\n  left: 12px;\n  border-radius: 8px;\n  &__header {\n        padding: 12px 16px;\n        height: 48px;\n        border-bottom: 1px solid #f1f5f7;\n        display: flex;\n        align-items: center;\n        gap: 24px;\n        a {\n        font-size: 14px;\n        font-weight: 300;\n        color: #151515;\n        }\n    }\n    &__links{\n        display: flex;\n        flex-direction: column;\n        gap: 4px;\n        li{\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n            padding: 12px 16px;\n            a{\n                font-size: 14px;\n                color: #151515;\n                font-weight: 500;\n            }\n        }\n    }\n    .active{\n        color: #008ADF;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
