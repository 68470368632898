// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  height: 100vh;
  position: relative;
}

.spinner-loader {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-loader .circle {
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  border-top: 8px solid #008ADF;
  border-right: 8px solid #0F103E;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

/* Inner circle */
.spinner-loader .circle-inner {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  border: 6px solid #008ADF;
  border-right-color: #0F103E;
  border-radius: 50%;
  animation: spin-reverse 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes fade-in {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/spinner.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,6BAAA;EACA,+BAAA;EACA,kBAAA;EACA,oCAAA;AACF;;AAEA,iBAAA;AACA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,yBAAA;EACA,2BAAA;EACA,kBAAA;EACA,4CAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF;AAEA;EACE;IACE,yBAAA;EAAF;EAEA;IACE,uBAAA;EAAF;AACF;AAGA;EACE;IACE,YAAA;EADF;EAGA;IACE,UAAA;EADF;AACF","sourcesContent":[".loader{\n  height: 100vh;\n  position: relative;\n}\n\n.spinner-loader {\n  position: absolute;\n  width: 100px;\n  height: 100px;\n  top: 30%;\n  left: 50%;\n  transform: translate(-50%,-50%);\n}\n\n.spinner-loader .circle {\n  width: 100%;\n  height: 100%;\n  border: 8px solid transparent;\n  border-top: 8px solid #008ADF;\n  border-right: 8px solid #0F103E;\n  border-radius: 50%;\n  animation: spin 1.2s linear infinite;\n}\n\n/* Inner circle */\n.spinner-loader .circle-inner {\n  position: absolute;\n  top: 20%;\n  left: 20%;\n  width: 60%;\n  height: 60%;\n  border: 6px solid #008ADF;\n  border-right-color: #0F103E;\n  border-radius: 50%;\n  animation: spin-reverse 1.5s linear infinite; \n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes spin-reverse {\n  from {\n    transform: rotate(360deg);\n  }\n  to {\n    transform: rotate(0deg);\n  }\n}\n\n@keyframes fade-in {\n  0%, 100% {\n    opacity: 0.8;\n  }\n  50% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
