import React from 'react'
import { useLocation } from 'react-router-dom'
import notFoundImg from '../../assets/images/notfound.svg'

import './alert.scss'

const AlertMessage = ({ text }) => {
  const location = useLocation()
  return (
        <div className="alert-message">
            <img src={notFoundImg} alt="" />
            <p style={location.pathname.startsWith('/corporate') ? {color: "#fff"} : {color: "#212121"}}>{text}</p>
        </div>
  )
}

export default AlertMessage
