import { getBlogsByCategory } from "../api/blog";
import { getCoursesByCategoryID } from "../api/course";
import { getEventsByCategory } from "../api/event";
import { getServicesByCategory } from "../api/service";

export const getDataByQueryType = (queryType, id) => {
  console.log("Query type: " + queryType);
  switch (queryType) {
    case 'blogs':
      return getBlogsByCategory(id);
    case 'courses':
      return getCoursesByCategoryID(id);
    case 'corporate/events':
      return getEventsByCategory(id);
    case 'corporate/services':
      return getServicesByCategory(id);
    default:
      throw new Error('Invalid query type');
  }
};
