import React, { useEffect, useState } from 'react'
import './services.scss'
import ServiceCard from '../../../components/Services/ServiceCard';
import CategoryList from '../../../components/Category';
import AlertMessage from '../../../components/Alert'
import { Helmet } from 'react-helmet';
import { getServices } from '../../../api/service';
import { getCategories } from '../../../api/category';

const Services = () => {
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [emptyState, setEmptyState] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getServices();
        setServices(data.services)
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


  const handleCategorySelect = (data) => {
    if (data === null) {
      setFilteredServices(services);
      setEmptyState(false);
    } else if (data.length === 0) {
      setEmptyState(true);
      setFilteredServices([]);
    } else {
      setEmptyState(false);
      setFilteredServices(data);
    }
  };

  
  return (
    <div className='services'>
          <Helmet>
            <title>Xidmətlərimiz</title>
          </Helmet>
        <div className="services__title">
            <h1>Xidmətlərimiz</h1>
            <p>İnnovativ texnologiyaları tətbiq edən əməkdaşlar şirkətin rəqabət qabiliyyətini artırır, məhsulun və xidmətin keyfiyyətini yüksəldir. Korporativ hədəflərinizə çatmaq üçün komandanızı korporativ həllərimizlə gücləndirin</p>
        </div>
        <CategoryList queryType="corporate/services" onCategorySelect={handleCategorySelect} />
        <div className="services__list">
            {emptyState ? (
              <AlertMessage text='Bu kateqoriyaya uyğun məlumat yoxdur.'/>
            ) : filteredServices.length > 0 ? (
              filteredServices.map(item => <ServiceCard key={item.id} data={item} />)
            ) : (
              services.map(item => <ServiceCard key={item.id} data={item} />)
            )}
          </div>
    </div>
  )
}

export default Services
