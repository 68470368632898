// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events__list__item {
  width: calc(33.3333333333% - 16px);
  height: 466px;
  background-color: rgba(255, 255, 255, 0.1215686275);
  border-radius: 20px;
  padding: 32px 20px;
}
.events__list__item .events__list__item__image {
  height: 230px;
}
.events__list__item .events__list__item__image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.events__list__item .events__list__item__content {
  margin-top: 20px;
}
.events__list__item .events__list__item__content h1 {
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 12px;
}
.events__list__item .events__list__item__content p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5019607843);
}
.events__list__item .events__list__item__content ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.events__list__item .events__list__item__content ul li {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5019607843);
  font-size: 16px;
  font-weight: 400;
}
.events__list__item .events__list__item__content ul li img {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .events__list__item {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Event/event.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,aAAA;EACA,mDAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACI,aAAA;AAER;AADQ;EACI,WAAA;EACA,YAAA;EACA,mBAAA;AAGZ;AAAI;EACI,gBAAA;AAER;AADQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAGZ;AADQ;EACI,eAAA;EACA,gBAAA;EACA,wCAAA;AAGZ;AADQ;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAGZ;AAFY;EACI,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,eAAA;EACA,gBAAA;AAIhB;AAHgB;EACI,iBAAA;AAKpB;;AAGA;EACI;IACG,WAAA;EAAL;AACF","sourcesContent":[".events__list__item{\n    width: calc(100%/3 - 16px);\n    height: 466px;\n    background-color: #FFFFFF1F;\n    border-radius: 20px;\n    padding: 32px 20px;\n    .events__list__item__image{\n        height: 230px;\n        img{\n            width: 100%;\n            height: 100%;\n            border-radius: 10px;\n        }\n    }\n    .events__list__item__content{\n        margin-top: 20px;\n        h1{\n            font-size: 20px;\n            font-weight: 600;\n            color: #FFFFFF;\n            margin-bottom: 12px;\n        }\n        p{\n            font-size: 16px;\n            font-weight: 400;\n            color: #FFFFFF80;\n        }\n        ul{\n            display: flex;\n            justify-content: space-between;\n            align-items: center;\n            margin-top: 40px;\n            li{\n                display: flex;\n                align-items: center;\n                color: #FFFFFF80;\n                font-size: 16px;\n                font-weight: 400;\n                img{\n                    margin-right: 8px;\n                }\n            }\n        }\n    }\n}\n\n\n@media screen and (max-width:768px) {\n    .events__list__item {\n       width: 100%;\n    }      \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
