// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.corporate-apply {
  padding: 0 120px;
  margin-top: 72px;
}

@media screen and (max-width: 768px) {
  .corporate-apply {
    padding: 0 12px;
    margin-top: 48px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/contact.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,eAAA;IACA,gBAAA;EACN;AACF","sourcesContent":[".corporate-apply{\n    padding: 0 120px;\n    margin-top: 72px;\n}\n\n@media screen and (max-width:768px) {\n    .corporate-apply{\n        padding: 0 12px;\n        margin-top: 48px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
