// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  height: 100vh;
  background-color: #F7F8FA;
}
.login .login-form {
  width: 30%;
  margin: 5rem auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
}
.login .login-form__container label {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}
.login .login-form__container input {
  width: 100%;
  height: 48px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  margin-top: 12px;
  padding-left: 15px;
}
.login .login-form__container button {
  background-color: #2970FF;
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #2970FF;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Login/login.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;AACJ;AAAI;EACI,UAAA;EACA,iBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,yBAAA;EACA,kBAAA;AAER;AAAY;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAEhB;AAAY;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AAEhB;AAAY;EACI,yBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AAEhB","sourcesContent":[".login{\n    height: 100vh;\n    background-color: #F7F8FA;\n    .login-form{\n        width: 30%;\n        margin: 5rem auto;\n        padding: 15px;\n        display: flex;\n        flex-direction: column;\n        gap: 24px;\n        border: 1px solid #D0D5DD;\n        border-radius: 8px;\n        &__container{\n            label{\n                font-size: 14px;\n                font-weight: 400;\n                color: #667085;\n            }\n            input{\n                width: 100%;\n                height: 48px;\n                border: 1px solid #D0D5DD;\n                border-radius: 8px;\n                margin-top: 12px;\n                padding-left: 15px;\n            }\n            button{\n                background-color: #2970FF;\n                width: 100%;\n                height: 48px;\n                color: #fff;\n                font-size: 16px;\n                font-weight: 400;\n                border-radius: 8px;\n                border: 1px solid #2970FF;\n                cursor: pointer;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
