// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.load-more {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

.load-more a {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  color: #008ADF;
}
.load-more a img {
  transition: 0.4s;
  margin-left: 12px;
}
.load-more a:hover img {
  transform: translateX(10px);
}

@media screen and (max-width: 768px) {
  .load-more {
    margin-top: 32px;
  }
  .load-more a {
    font-size: 14px;
  }
  .load-more a img {
    transition: 0.4s;
  }
  .load-more a:hover img {
    transform: translateX(10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadMore/load.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACJ;AAAI;EACI,gBAAA;EACA,iBAAA;AAER;AACQ;EACI,2BAAA;AACZ;;AAIA;EACI;IACI,gBAAA;EADN;EAEM;IACI,eAAA;EAAV;EACU;IACI,gBAAA;EACd;EAEc;IACI,2BAAA;EAAlB;AACF","sourcesContent":[".load-more{\n    display: flex;\n    justify-content: center;\n    margin-top: 48px;\n}\n\n.load-more a{\n    display: flex;\n    align-items: center;\n    font-size: 20px;\n    font-weight: 300;\n    color: #008ADF;\n    img{\n        transition: .4s;\n        margin-left: 12px;\n    }\n    &:hover{\n        img{\n            transform: translateX(10px);\n        }\n    }\n}\n\n@media screen and (max-width:768px) {\n    .load-more{\n        margin-top: 32px;\n        a{\n            font-size: 14px;\n            img{\n                transition: .4s;\n            }\n            &:hover{\n                img{\n                    transform: translateX(10px);\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
