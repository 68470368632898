import React from 'react'

import './advantage.scss'
import AdvantageCard from './Card'

const Advantages = () => {
  return (
    <h1></h1>
  )
}

export default Advantages