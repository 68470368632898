// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apply-btn {
  width: 174px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
  padding: 12px 24px;
  background-color: #008ADF;
  color: #fff;
  border: 1px solid #008ADF;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(45deg, #fff 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: all 300ms ease-in-out;
}
.apply-btn img {
  filter: brightness(0) invert(1);
  margin-left: 8px;
  transition: filter 300ms ease-in-out;
}
.apply-btn:hover {
  background-position: 0;
  color: #008ADF;
}
.apply-btn:hover img {
  filter: none;
}

@media screen and (max-width: 768px) {
  .apply-btn {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ApplyButton/button.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,mEAAA;EACA,yBAAA;EACA,qBAAA;EAEA,iCAAA;AACJ;AAAI;EACI,+BAAA;EACA,gBAAA;EAEA,oCAAA;AAER;AAAI;EACI,sBAAA;EACA,cAAA;AAER;AADQ;EACI,YAAA;AAGZ;;AAIA;EACI;IACI,eAAA;EADN;AACF","sourcesContent":[".apply-btn{\n    width: 174px;\n    height: 48px;\n    font-size: 16px;\n    font-weight: 300;\n    padding: 12px 24px;\n    background-color: #008ADF;\n    color: #fff;\n    border: 1px solid #008ADF;\n    border-radius: 8px;\n    cursor: pointer;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-image: -webkit-linear-gradient(45deg, #fff 50%, transparent 50%);\n    background-image: linear-gradient(45deg, #fff 50%, transparent 50%);\n    background-position: 100%;\n    background-size: 400%;\n    -webkit-transition: all 300ms ease-in-out;\n    transition: all 300ms ease-in-out;\n    img{\n        filter: brightness(0) invert(1);\n        margin-left: 8px;\n        -webkit-transition: filter 300ms ease-in-out;\n        transition: filter 300ms ease-in-out;\n    }\n    &:hover{\n        background-position: 0;\n        color: #008ADF;\n        img{\n            filter: none ;\n        }\n    }\n   \n}\n\n\n@media screen and (max-width:768px) {\n    .apply-btn{\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
