// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cv-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5137254902);
  position: absolute;
  top: 0;
  left: 0;
}
.cv-modal .modal-content {
  background-color: #fff;
  position: absolute;
  top: 10%;
  left: 60%;
  transform: translateX(-60%);
  width: 990px;
  height: 300px;
  border-radius: 12px;
}
.cv-modal .modal-content .modal-header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.cv-modal .modal-content .modal-header h1 {
  font-size: 20px;
  font-weight: 400;
}
.cv-modal .modal-content .modal-header svg {
  cursor: pointer;
}

.cv-modal {
  display: none;
}

.cv-modal.active {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/CvModal/cv-modal.scss"],"names":[],"mappings":"AAAA;EACQ,WAAA;EACA,YAAA;EACA,6CAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AACR;AAAQ;EACI,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAEZ;AADY;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAGhB;AAFgB;EACI,eAAA;EACA,gBAAA;AAIpB;AAFgB;EACI,eAAA;AAIpB;;AAEA;EACI,aAAA;AACJ;;AAEE;EACE,cAAA;AACJ","sourcesContent":[".cv-modal{\n        width: 100%;\n        height: 100%;\n        background-color: #00000083;\n        position: absolute;\n        top: 0;\n        left: 0;\n        .modal-content{\n            background-color: #fff;\n            position: absolute;\n            top: 10%;\n            left: 60%;\n            transform: translateX(-60%);\n            width: 990px;\n            height: 300px;\n            border-radius: 12px;\n            .modal-header{\n                height: 72px;\n                display: flex;\n                align-items: center;\n                justify-content: space-between;\n                padding: 0 15px;\n                h1{\n                    font-size: 20px;\n                    font-weight: 400;\n                }\n                svg{\n                    cursor: pointer;\n                }\n            }\n        }\n}\n\n.cv-modal {\n    display: none;\n}\n  \n  .cv-modal.active {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
