// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories {
  height: 80px;
  background: rgba(26, 27, 94, 0.5019607843);
  border-radius: 20px;
  border: 1px solid #0F103F;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin: 48px auto;
  padding: 16px;
}
.categories li {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: max-content;
  min-width: 140px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  border-radius: 16px;
  padding: 16px 34px;
  color: #8E9198;
  cursor: pointer;
  align-content: center;
}
.categories li:nth-child(1) a {
  color: #008ADF;
}
.categories .active {
  background-color: #008ADF;
  border-radius: 16px;
  padding: 16px 34px;
  color: #fff !important;
}
.categories .active a {
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .categories {
    overflow-x: scroll !important;
    width: 100% !important;
  }
  .categories li {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Category/category.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,0CAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,iBAAA;EACA,aAAA;AACJ;AAAI;EACI,YAAA;EACA,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;AAER;AAAY;EACI,cAAA;AAEhB;AAEI;EACI,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AAAR;AACQ;EACI,sBAAA;AACZ;;AAIA;EACI;IACI,6BAAA;IACA,sBAAA;EADN;EAEM;IACI,eAAA;EAAV;AACF","sourcesContent":[".categories {\n    height: 80px;\n    background: rgba(26, 27, 94, 0.5019607843);\n    border-radius: 20px;\n    border: 1px solid #0F103F;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start; \n    gap: 16px;\n    margin: 48px auto;\n    padding: 16px;\n    li {\n        flex-grow: 1;\n        flex-shrink: 0;\n        max-width: max-content;\n        min-width: 140px;\n        text-align: center;\n        font-size: 18px;\n        font-weight: 300;\n        border-radius: 16px;\n        padding: 16px 34px;\n        color: #8E9198;\n        cursor: pointer;\n        align-content: center;\n        &:nth-child(1) {\n            a{\n                color: #008ADF;\n            }\n        }\n    }\n    .active {\n        background-color: #008ADF;\n        border-radius: 16px;\n        padding: 16px 34px;\n        color: #fff !important;\n        a{\n            color: #fff !important;\n        }\n    }\n}\n\n@media screen and (max-width:768px) {\n    .categories {\n        overflow-x: scroll !important;\n        width: 100% !important;\n        li {\n            font-size: 14px;\n        }\n    }      \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
