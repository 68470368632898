import React from 'react'
import arrowUpRight from '../../../assets/images/services-icon.svg'
import { Link } from 'react-router-dom'


const ServiceCard = ({ data }) => {
  return (
    <div className='services__list__item'>
      <div className='services-arrow'>
        <Link to={`/corporate/services/${data.id}`} title={`Learn more about ${data.title}`}>
          <img src={arrowUpRight} alt="Service details arrow" />
        </Link>
      </div>
      <div className="services__list__item__elem">
        <Link to={`/corporate/services/${data.id}`}>{data.title}</Link>
        <p>{data.description.length > 100 ? data.description.slice(0,100) + "..." : data.description}</p>
      </div>
    </div>
  )
}

export default ServiceCard

